<template>
  <!-- Table Container Card -->
  <b-card no-body>

    <b-card-body class="text-center">
      <b-card-title>{{ company.name }}</b-card-title>
      <b-card-sub-title>
        <div>{{ $t('apps.reportProfitLoss.singular.purchaseInvoiceDetailReport') }}</div>
        <div class="mt-sm-1">{{ periodReportText }}</div>
      </b-card-sub-title>
    </b-card-body>

    <b-table-simple id="profit-loss-table" sticky-header="228px">
      <b-thead>
        <b-tr>
          <b-th v-for="column in tableColumns" :key="column.key" :class="column.thClass" >{{ column.label }}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(prop, index) in itemLists" :key="prop.coa">
          <b-td
            v-for="(column, idx) in tableColumns"
            :key="`${column.key}-${index}`"
            :class="stylingCOA(prop.rowNumber, column.tdClass, idx)">
            {{ prop[column.key] }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div class="pt-2 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-end">
      <div class="mb-0 h4 font-weight-bolder">Profit / Loss Rp. {{ itemLists[itemLists.length - 1] ? itemLists[itemLists.length - 1].balance_view : '0,00' }}</div>
    </div>
    <div class="pt-2 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-end">
      <b-alert
        variant="danger"
        :show="isAlert"
      >
        <div class="alert-body">
          {{ alertText }}
        </div>
      </b-alert>
    </div>

    <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between">
      <b-button
        class="mb-sm-1 mb-md-0"
        variant="outline-primary"
        :to="{ name: 'apps-transactions-closing-list' }"
      >
        <feather-icon
          icon="ArrowLeftIcon"
        />
        {{ $t('globalActions.backToList') }}
      </b-button>

      <b-button
        v-if="!form.period.is_closed"
        variant="primary"
        type="submit"
        @click="handleSubmit"
        :disabled="isAlert"
      >
        <feather-icon
          icon="SaveIcon"
        />
        {{ $t('apps.transactionsClosing.actions.close') }}
      </b-button>

      <b-button
        v-else
        variant="primary"
        type="submit"
        @click="handleSubmit"
        :disabled="isAlert"
      >
        <feather-icon
          icon="SaveIcon"
        />
        {{ $t('apps.transactionsClosing.actions.reopen') }}
      </b-button>
    </div>

  </b-card>

</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  // BTable,
  // BSpinner,
  BButton,
  BAlert,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody
} from 'bootstrap-vue'

import { startOfDay, startOfMonth, endOfMonth, intervalToDuration, format, parse } from 'date-fns'
import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import router from '@/router'
import store from '@/store/index'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// to access i18n
// import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BAlert,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody
  },
  setup () {
    const form = ref({
      id: router.currentRoute.params.id,
      period: {},
      dateStart: '',
      dateEnd: ''
    })

    const company = ref(store.state.user.currentCompany)
    const periodReportText = ref()
    const isAlert = ref()
    const alertText = ref()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { toast, $put, $get } = useHttp()

    // const { $t } = useLocalization()

    // Table Handlers
    const tableColumns = [
      { key: 'coa', label: 'Account' },
      { key: 'balance_view', label: 'Amount', thClass:'text-right', tdClass: 'text-right' }
    ]

    const getReport = async () => {
      const params = [
        `dateStart=${form.value.dateStart}`,
        `dateEnd=${form.value.dateEnd}`,
        `officeId=null`,
        'tags={}'
      ]

      const url = `reports/profit-loss?${ params.join('&') }`
      await fetchLists(url)
    }

    const getPeriod = async () => {
      const period = await $get({url: `transaction/book-period/${form.value.id}`})
      form.value.period = period.data
      const formatString = 'dd/MM/yyyy'
      const thisPeriod = new Date(form.value.period.year, form.value.period.month, 0)
      form.value.dateStart = format(startOfMonth(thisPeriod), formatString)
      form.value.dateEnd = format(endOfMonth(thisPeriod), formatString)
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      periodReportText.value = `${formatStartDate} to ${formatEndDate}`
    }

    const setPadding = (row) => {
      return `padding-left: ${(row.account_level - 1) * 30}px;`
    }

    const stylingCOA = (rowNumber, tdClass, idx) => {
      return {
        [tdClass]: true,
        'text-right pr-0': idx === 0 && rowNumber === 4,
        'header-category-account': rowNumber === 0,
        'child-account': rowNumber === 2,
        'total-category-account': rowNumber === 1 || rowNumber === 3,
        'total-top-category-account': rowNumber === 4
      }
    }

    const firstCompanyPeriod = ref()
    const getFirstPeriod = async () => {
      const periods = await $get({url: 'transaction/book-period'})
      const firstPeriod = periods.data[0]
      firstCompanyPeriod.value = new Date(firstPeriod.year, firstPeriod.month, 0)
    }

    const checkAlert = () => {
      const thisPeriod = new Date(form.value.period.year, form.value.period.month, 0)
      const lastCompanyPeriod = new Date(company.value.closing_year, company.value.closing_month, 0)

      const periodInterval = intervalToDuration({
        start: thisPeriod,
        end: lastCompanyPeriod
      })

      if (!company.value.closing_year) {
        isAlert.value = thisPeriod.toString() !== firstCompanyPeriod.value.toString()
        alertText.value = 'Please close the previous month first'
      } else {
        if (!form.value.period.is_closed) {
          isAlert.value = periodInterval.months > 1 || periodInterval.years > 0
          alertText.value = 'Please close the previous month first'
        } else {
          isAlert.value = thisPeriod < lastCompanyPeriod
          alertText.value = 'Please open next month first'
        }
      }
    }

    onMounted(async () => {
      await getPeriod()
      await getReport()
      await getFirstPeriod()
      updatePeriodText()
      checkAlert()
    })

    return {
      setPadding,
      stylingCOA,
      form,
      toast,
      $put,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      formatCurrency,
      tableColumns,
      periodReportText,
      updatePeriodText,
      isAlert,
      alertText,
      company
    }
  },
  methods: {
    handleSubmit () {
      const form = {
        is_closed: !this.form.period.is_closed,
        closing_date: format(startOfDay(new Date()), 'yyyy-MM-dd')
      }

      const $swal = this.$swal
      $swal({
        title: `${ !this.form.period.is_closed ? this.$t('confirm.closeThis') : this.$t('confirm.openThis') } ${this.$t('apps.transactionsClosing.singular.period')} ?`,
        text: `${ !this.form.period.is_closed ? this.$t('apps.transactionsClosing.alert.afterClosed') : this.$t('apps.transactionsClosing.alert.afterOpened') }`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async ({value: password}) => {
        if (password) {
          const callbackSuccess = (res) => {
            store.commit('user/SET_CURRENT_COMPANY', res.data.company)
            router.replace({ name: 'apps-transactions-closing-list' })
            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: `${this.$t('feedback.success')} ${ !this.form.period.is_closed ? this.$t('confirm.closeThis') : this.$t('confirm.openThis') } ${this.$t('apps.transactionsClosing.singular.period')}.`
              }
            })
          }

          this.$put({
            data: form,
            url: `transaction/book-period/${this.form.id}`
          }).then((res) => callbackSuccess(res))
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
